import React from "react";
import { useSelector } from "react-redux";
import { selectNewPaperworksList } from "store/PaperWork/selectors";
import { CATEGORY_CARTOGRAPIC_DOC, CATEGORY_DDJJ, CATEGORY_HORIZONTAL_PROP, filterByValue } from "../../functions";
import Category from "./components/Category";
import { Wrapper } from "./styled";

const NewPaperwork = ({ search }) => {
  const newPaperworkList = useSelector(selectNewPaperworksList);
  const filterlist = filterByValue(newPaperworkList, search);
  const horizontalProp = filterlist.filter((e) => CATEGORY_HORIZONTAL_PROP.includes(e.id));
  const cartographicDoc = filterlist.filter((e) => CATEGORY_CARTOGRAPIC_DOC.includes(e.id));
  const ddjj = filterlist.filter((e) => CATEGORY_DDJJ.includes(e.id));
  const others = filterlist.filter(
    (e) => !CATEGORY_HORIZONTAL_PROP.includes(e.id) && !CATEGORY_CARTOGRAPIC_DOC.includes(e.id) && !CATEGORY_DDJJ.includes(e.id)
  );

  return (
    <Wrapper>
      <Category data={others} name={null} />
      <Category data={horizontalProp} name={"Registro de plano de propiedad horizontal"} />
      <Category data={cartographicDoc} name={"Venta de documentación cartografica"} />
      <Category data={ddjj} name={"Presentación de declaración jurada"} />
    </Wrapper>
  );
};

export default NewPaperwork;
