import React, { useState } from "react";
import { Wrapper } from "./styled";
import Buttons from "./components/Buttons";
import Select from "./components/Select";
import { USER_PROFILE, USER_PROFILE_ID } from "store/Login/initialState";
import { useSelector } from "react-redux";
import { selectParams } from "store/Modals/selectors";
import NameInput from "./components/NameInput";
import EmailInput from "./components/EmailInput";
import PasswordInput from "./components/PasswordInput";

const Content = ({ modal, confirm, edit }) => {
  const user = useSelector(selectParams);
  const isDbUser = !user?.firebaseDataId && user?.id;
  const [updateUser, setUpdateUser] = useState({
    name: user?.name,
    email: user?.email,
    password: user?.password,
    profileId: user?.profileId,
  });
  const profiles = [
    { name: USER_PROFILE[USER_PROFILE_ID.ADMINISTRATOR], id: USER_PROFILE_ID.ADMINISTRATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.OPERATOR], id: USER_PROFILE_ID.OPERATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.CERTIFIER], id: USER_PROFILE_ID.CERTIFIER },
    { name: USER_PROFILE[USER_PROFILE_ID.SUBSANATOR], id: USER_PROFILE_ID.SUBSANATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.VALUATION_OPERATOR], id: USER_PROFILE_ID.VALUATION_OPERATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR], id: USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR },
  ];

  const onChange = (e) => {
    setUpdateUser({
      ...updateUser,
      [e.target.name]: e.target.value,
    });
  };

  const setProfile = (i) => {
    setUpdateUser({
      ...updateUser,
      ["profileId"]: i.id,
    });
  };

  return (
    <Wrapper>
      <NameInput onChange={onChange} name="name" val={updateUser.name} updateUser={updateUser} setUpdateUser={setUpdateUser} />
      <EmailInput onChange={onChange} name="email" val={updateUser.email} updateUser={updateUser} setUpdateUser={setUpdateUser} />
      <PasswordInput
        required={!edit}
        onChange={onChange}
        name="password"
        val={updateUser.password}
        updateUser={updateUser}
        setUpdateUser={setUpdateUser}
        disabled={isDbUser}
      />
      <Select
        profiles={profiles}
        name="profileId"
        label="Tipo de perfil"
        onChange={setProfile}
        placeholder="Seleccione el tipo de perfil"
        val={USER_PROFILE[updateUser.profileId]}
      />
      <Buttons modal={modal} confirm={confirm} user={updateUser} edit={edit} />
    </Wrapper>
  );
};

export default Content;
