import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  /* height: 174px; */
  font-size: 60px;
  box-sizing: border-box;
  column-gap: 16px;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
