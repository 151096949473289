import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  box-sizing: border-box;
  width: 100%;
  padding-top: 38px;
  overflow-y: visible;
  @media (max-width: 980px) {
    overflow-y: auto;
  }
  height: 85%;
`;
