import { get } from "lodash";
import { initialState } from "./initialState";
import { selectUserProfileId } from "store/Login/selectors";
import { FILTER_TYPES, FILTER_TYPE_BY_ROLE } from "./constants";
import { isEmpty } from "lodash";

export const selectFilterByKey = (state, filter) => get(state, `filters.${filter}`, false);

export const selectFilters = (state) => {
  const filters = get(state, "filters", initialState);
  const filtersCopy = { ...filters };
  const profileId = selectUserProfileId(state);
  if (!isEmpty(FILTER_TYPE_BY_ROLE[profileId])) filtersCopy[FILTER_TYPES] = FILTER_TYPE_BY_ROLE[profileId];
  return filtersCopy;
};
