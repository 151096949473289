import Pagination from "components/commons/Pagination";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPaperworkPage } from "store/PaperWork/actions/actions";
import { selectPaperworkPageSize, selectPaperworkTotalCount, selectPaperworkActualPage } from "store/PaperWork/selectors";
import Wrapper from "./styled";
import { getPaperworkHistoryFromFilters } from "store/PaperWork/actions/requests";

const Footer = () => {
  const dispatch = useDispatch();
  const paperworkCount = useSelector(selectPaperworkTotalCount);
  const pageSize = useSelector(selectPaperworkPageSize);
  const actualPage = useSelector(selectPaperworkActualPage);

  useEffect(() => {
    dispatch(getPaperworkHistoryFromFilters());
  }, [actualPage]);

  useEffect(() => {
    return () => dispatch(setPaperworkPage(0));
  }, []);

  const nextPage = () => {
    dispatch(setPaperworkPage(actualPage + 1));
  };

  const prevPage = () => {
    dispatch(setPaperworkPage(actualPage - 1));
  };

  return (
    <Wrapper>
      <tr>
        <td>
          <Pagination pageNumber={actualPage} limit={pageSize} total={paperworkCount} onPrev={prevPage} onNext={nextPage}/>
        </td>
      </tr>
    </Wrapper>
  );
};

export default Footer;
