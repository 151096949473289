import React, { useEffect, useState } from "react";
import PrimaryCheckBox from "components/commons/Checkboxs/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "store/PaperWork/actions/functions";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { checkDependecies, getInitialValue, getValues } from "../../functions";
import { get } from "lodash";

const Checkbox = ({ item, stageKey }) => {
  const dispatch = useDispatch();
  const { label, description, dependencies, key, disabled } = item;
  const metadata = useSelector(selectPaperworkMetadata);
  const stage = get(metadata, "stageProgress", {});
  const index = useSelector(selectStageIndex);
  const values = checkDependecies(stage, dependencies, false, disabled);
  const [selected, setSelected] = useState(getInitialValue(stage, index, stageKey, key, values.value));
  const check = () => setSelected(!selected);
  const inDisuse = values.hidden || values.disabled;

  useEffect(
    () => dispatch(setForm(stageKey, key, getValues(selected, "value", dependencies, stage, values, false), values.hidden)),
    [selected, inDisuse]
  );

  if (values.hidden) return null;

  return (
    <PrimaryCheckBox
      label={label}
      onClick={check}
      value={getValues(selected, "value", dependencies, stage, values)}
      disabled={values.disabled}
      description={description}
    />
  );
};

export default Checkbox;
