import { loadingStop } from "store/General/actions";
import { success } from "store/Modals/actions";
import { getPendingUsers, getUsers } from "./requests";
import { internalSignOut, updateUser } from "store/Login/actions/actions";
import { USER_PROFILE_ID } from "store/Login/initialState";

export const handlingUsers = (response) => {
  return (dispatch) => {
    dispatch(loadingStop());
    dispatch(getUsers());
    dispatch(success(response.data.code));
  };
};

export const handlingPendingUsers = (response) => {
  return (dispatch) => {
    dispatch(loadingStop());
    dispatch(getPendingUsers());
    dispatch(success(response.data.code));
  };
};

export const handlingUpdateUser = (response, currentUser, user) => {
  return (dispatch) => {
    dispatch(loadingStop());
    if (currentUser.uid === response.data.result.firebaseData?.uid && currentUser.profileId != user.profileId) dispatch(internalSignOut());
    else dispatch(getUsers());
    dispatch(success(response.data.code));
  };
};

export const handlingDeleteUser = (response, currentUser, users) => {
  return (dispatch) => {
    dispatch(loadingStop());
    const anotherAdmin = users.some((u) => u.profileId === USER_PROFILE_ID.ADMINISTRATOR && u.uid !== currentUser.uid);
    if (currentUser.id === response.data.result?.deleted && anotherAdmin) dispatch(internalSignOut());
    else dispatch(getUsers());
    dispatch(success(response.data.code));
  };
};

export const handlingTestUpdateProfile = (response) => {
  return (dispatch) => {
    dispatch(loadingStop());
    dispatch(updateUser(response.data.result));
    let userCopy = JSON.parse(localStorage.getItem("user"));
    userCopy.profileId = response.data.result.profileId;
    localStorage.setItem("user", JSON.stringify(userCopy));
  };
};
