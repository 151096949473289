import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkListByState } from "store/PaperWork/selectors";
import Item from "../Item";
import { ContainerWrap, Wrapper, PaginationWrap } from "./styled";
import { PAPERWORK_STATE, PAPERWORK_TYPE } from "store/PaperWork/constants";
import { openModal } from "store/Modals/actions";
import { MODAL_SHOW_REJECTION } from "store/Modals/constants";
import { setCurrentRejection } from "store/PaperWork/actions/actions";
import get from "lodash/get";
import { push } from "connected-react-router";
import { getUserPaperworks } from "store/PaperWork/actions/requests";
import { FINISHED_KEY } from "../../functions";
import { FILTERS_HOME_DEFAULT } from "../../../Selector/components/Filters/constants";
import { filterByState } from "../../functions";
import { selectFilters } from "store/Filters/selectors";
import { getCustomFilters } from "store/Filters/functions";
import Pagination from "components/commons/Pagination";
import { setPaperworkPage } from "store/PaperWork/actions/actions";
import { selectPaperworkTotalCount, selectPaperworkPageSize, selectPaperworkActualPage } from "store/PaperWork/selectors";

const FinishedPaperwork = ({ search }) => {
  const dispatch = useDispatch();

  const storeFilters = useSelector(selectFilters);
  const actualPage = useSelector(selectPaperworkActualPage);
  useEffect(() => {
    const customFilters = getCustomFilters(storeFilters, FILTERS_HOME_DEFAULT[FINISHED_KEY]);
    dispatch(getUserPaperworks(customFilters));
  }, [actualPage]);

  useEffect(() => {
    return () => dispatch(setPaperworkPage(0));
  }, []);
  
  const paperworkCount = useSelector(selectPaperworkTotalCount);
  const pageSize = useSelector(selectPaperworkPageSize);

  const FINISHED_STATES = [PAPERWORK_STATE.APPROVED, PAPERWORK_STATE.REJECTED];

  const ENABLED_DETAIL = [PAPERWORK_TYPE.D1, PAPERWORK_TYPE.D2, PAPERWORK_TYPE.D5];

  const paperworkList = useSelector((state) => selectPaperworkListByState(state, FINISHED_STATES));

  const filteredList = filterByState(paperworkList, search);

  const Card = ({ item }) => {
    const show = item.state === PAPERWORK_STATE.REJECTED || ENABLED_DETAIL.includes(item.paperworkId);

    const onClick = () => {
      if (item.state === PAPERWORK_STATE.REJECTED) {
        const rejection = get(item, "rejection", []);
        dispatch(setCurrentRejection(rejection?.reason));
        dispatch(openModal(MODAL_SHOW_REJECTION));
        return;
      }
      if (ENABLED_DETAIL.includes(item.paperworkId)) {
        dispatch(push(`/administracion/detalle/${item.paperworkProgressId}`));
        return;
      }
      return null;
    };

    return (
      <Item
        key={item.paperworkProgressId}
        state={item.state}
        title={item.paperworkProgressId}
        detail={item.paperworkName}
        onClick={show ? onClick : null}
      />
    );
  };

  const nextPage = () => {
    dispatch(setPaperworkPage(actualPage + 1));
  };

  const prevPage = () => {
    dispatch(setPaperworkPage(actualPage - 1));
  };

  return (
    <Wrapper>
      <ContainerWrap>
        {filteredList.map((item) => (
          <Card key={item.paperworkProgressId} item={item} />
        ))}
      </ContainerWrap>
      <PaginationWrap>
        <Pagination pageNumber={actualPage} limit={pageSize} total={paperworkCount} onPrev={prevPage} onNext={nextPage}/>
      </PaginationWrap>
    </Wrapper>
  );
};

export default FinishedPaperwork;
