import React from "react";
import { Wrapper, Container, ButtonWrapper, Image, Title, Description, Subtitle } from "./style";
import { MODAL_SENT_PAPERWORK } from "store/Modals/constants";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setParams } from "store/Modals/actions";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import Ilust2 from "images/ilustrations/ilustration_2.svg";
import { selectParams } from "store/Modals/selectors";

const SentPaperworkModal = () => {
  const dispatch = useDispatch();
  const { paperworkProgressId } = useSelector(selectParams);

  const onClose = () => {
    dispatch(closeModal(MODAL_SENT_PAPERWORK));
    dispatch(setParams({}));
  };

  return (
    <Wrapper>
      <Container>
        <Title>¡Trámite enviado!</Title>
        <Subtitle>Número de trámite: #{paperworkProgressId}</Subtitle>
        <Image src={Ilust2} alt="img" />
        <Description>A la brevedad un agente de catastro estará observando tu trámite.</Description>
        <ButtonWrapper>
          <LabelOnlyButton color={colors.white} background={colors.blue50} border={colors.blue50} onClick={onClose} label="Aceptar" />
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default SentPaperworkModal;
