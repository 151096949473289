import React from "react";
import { Wrapper, CheckBoxContainer } from "./styled";
import Header from "./components/Header";
import CheckBox from "components/commons/Checkboxs/CheckBox/components/Button";
import { USER_PROFILE_ID } from "store/Login/initialState";
import { useSelector } from "react-redux";
import { selectIsAllowed } from "store/Login/selectors";

const SignedPdf = ({ signed, onChange }) => {
  const allowedRoles = [USER_PROFILE_ID.ADMINISTRATOR, USER_PROFILE_ID.CERTIFIER];
  const allowed = useSelector((state) => selectIsAllowed(state, allowedRoles));

  if (!allowed) return null;
  return (
    <Wrapper>
      <Header title={"PDF Firmado"} />
      <CheckBoxContainer>
        <CheckBox checked={signed} onClick={onChange} />
      </CheckBoxContainer>
    </Wrapper>
  );
};

export default SignedPdf;
