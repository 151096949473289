/* eslint-disable no-unused-vars */
import { loadingStop } from "store/General/actions";
import { setPaperworkList, updatePaperworkMetadata } from "./actions";
import { openModal, setParams, success } from "store/Modals/actions";
import { PATH_DASHBOARD, PATH_HOME } from "components/pages/App/constants";
import { saveAs } from "file-saver";
import { setPaperworksCountStatistics } from "./actions";
import { goBack, push } from "connected-react-router";
import { getFileName } from "utils/functions";
import { MODAL_SENT_PAPERWORK, MODAL_WARN_MULTIPLE_PLOTS } from "store/Modals/constants";
import { PAPERWORK_WITHOUT_PREVIEW } from "../constants";

export const handlingPaperworkHistory = (result) => {
  return (dispatch) => {
    dispatch(setPaperworkList(result));
    dispatch(loadingStop());
  };
};

export const handlingSendPapwToReview = (type, pProgId) => {
  return (dispatch) => {
    dispatch(loadingStop());
    dispatch(openModal(MODAL_SENT_PAPERWORK));
    dispatch(setParams({ paperworkProgressId: pProgId }));
    if (!PAPERWORK_WITHOUT_PREVIEW.includes(type)) dispatch(push(`/tramites/reporte/${pProgId}`));
    else dispatch(push(PATH_HOME));
  };
};

export const handlingObservePaperwork = (response) => {
  return (dispatch) => {
    dispatch(loadingStop());
    dispatch(success(response.data.code));
    dispatch(goBack(PATH_DASHBOARD));
  };
};

export const handlingUpdateMetadata = (response, id) => {
  return (dispatch) => {
    const updatedPaperworkMetadata = response.data.result;
    dispatch(updatePaperworkMetadata(updatedPaperworkMetadata));
    dispatch(loadingStop());
    dispatch(success(response.data.code));
  };
};

export const handlingDownload = (response, fileName) => {
  return (dispatch) => {
    dispatch(loadingStop());
    const file = new Blob([response.data], { type: response.headers["content-type"] });
    saveAs(file, getFileName(file.type, fileName));
  };
};

export const handlingReportPrint = (response) => {
  return (dispatch) => {
    dispatch(loadingStop());
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    const pdfTab = window.open(fileURL);
    if (pdfTab) pdfTab.print();
  };
};

export const handlingGetPaperworksStatistics = (result, chart) => {
  return (dispatch) => {
    dispatch(loadingStop());
    dispatch(setPaperworksCountStatistics(result, chart));
  };
};
