import Select from "components/commons/Modals/UserModal/components/Content/components/Select";
import { DEVELOPMENT_FUNCTIONS } from "components/pages/App/constants";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { testUpdateProfile } from "store/BackOffice/actions/requests";
import { USER_PROFILE, USER_PROFILE_ID } from "store/Login/initialState";
import { selectUser, selectUserProfileId } from "store/Login/selectors";

const ProfileSelect = () => {
  const dispatch = useDispatch();
  const profileId = useSelector(selectUserProfileId);
  const user = useSelector(selectUser);
  const profiles = [
    { name: USER_PROFILE[USER_PROFILE_ID.ADMINISTRATOR], id: USER_PROFILE_ID.ADMINISTRATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.OPERATOR], id: USER_PROFILE_ID.OPERATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.CERTIFIER], id: USER_PROFILE_ID.CERTIFIER },
    { name: USER_PROFILE[USER_PROFILE_ID.SUBSANATOR], id: USER_PROFILE_ID.SUBSANATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.VALUATION_OPERATOR], id: USER_PROFILE_ID.VALUATION_OPERATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR], id: USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.CONTRIBUTOR], id: USER_PROFILE_ID.CONTRIBUTOR },
  ];

  const onChange = (e) => {
    dispatch(
      testUpdateProfile(user.id, {
        name: user?.name,
        email: user?.email,
        password: user?.password,
        profileId: e.id,
      })
    );
  };

  if (!DEVELOPMENT_FUNCTIONS) return null;
  return (
    <Select
      profiles={profiles}
      name="profileId"
      label="Perfil de prueba"
      onChange={(e) => onChange(e)}
      placeholder="Seleccione el tipo de perfil"
      val={USER_PROFILE[profileId]}
    />
  );
};

export default ProfileSelect;
