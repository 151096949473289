import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "store/Filters/actions";
import { selectFilterByKey } from "store/Filters/selectors";
import { Wrapper } from "./style";
import CommonInput from "components/commons/Inputs/CommonInput";

const Form = ({ section }) => {
  const dispatch = useDispatch();
  const { elements, key } = section;
  const filterValue = useSelector((state) => selectFilterByKey(state, key));
  const [value, setValue] = useState(filterValue);
  const length = section.length;
  const [valResult, setValResult] = useState(filterValue);

  const onChange = (e) => {
    setValResult(autocomplete(e.target.value.toString()));
    setValue(e.target.value);
  };

  const autocomplete = (value) => {
    if (value === "") {
      return "";
    }
    return value.padStart(length, "0");
  };

  const onBlur = () => {
    setValue(valResult);
    dispatch(setFilters(key, valResult));
  };

  return (
    <Wrapper>
      {elements.map((element) => (
        <CommonInput key={key} placeholder={element.placeholder} value={value} onChange={onChange} onBlur={onBlur} />
      ))}
    </Wrapper>
  );
};

export default Form;
