/* eslint-disable no-unused-vars */
import * as types from "../types";
import { getPaperworkHistoryFromFilters } from "./requests";

export function setSeeMorePaperwork(data) {
  return {
    type: types.SET_SEE_MORE_PAPERWORK,
    data,
  };
}

export function setSubsanationAreas(subsanationAreas) {
  return {
    type: types.SET_SUBSANATION_AREAS,
    subsanationAreas,
  };
}

export function setCurrentPaperworkTypes(paperworkTypes) {
  return {
    type: types.SET_CURRENT_PAPERWORK_TYPES,
    paperworkTypes,
  };
}

export function setNewPaperworksList(value) {
  return {
    type: types.SET_NEW_PAPERWORKS_LIST,
    value,
  };
}

export function setStageIndex(value) {
  return {
    type: types.SET_STAGE_INDEX,
    value,
  };
}

export function setStageData(field, data) {
  return {
    type: types.SET_STAGE_DATA,
    field,
    data,
  };
}

export function setStageStructure(index, field, data) {
  return {
    type: types.SET_STAGE_STRUCTURE,
    index,
    field,
    data,
  };
}

export function setStage(index, stage) {
  return {
    type: types.SET_STAGE,
    index,
    stage,
  };
}

export function setStageCompleted(state) {
  return {
    type: types.SET_STAGE_COMPLETED,
    state,
  };
}

export function setFormErrors(data) {
  return {
    type: types.SET_FORM_ERRORS,
    data,
  };
}

export function resetPaperworkStore() {
  return {
    type: types.RESET_PAPERWORK_STORE,
  };
}

export function setPaperworkList(response) {
  return {
    type: types.SET_PAPERWORK_LIST,
    response,
  };
}

export function setPaperwDetail(response) {
  return {
    type: types.SET_PAPERWORK_DETAIL,
    response,
  };
}

export function setPaperworkPage(actualPage) {
  return async (dispatch) => {
    dispatch({ type: types.SET_PAPERWORK_PAGE, actualPage });
  };
}

export function setReportLink(link) {
  return async (dispatch) => {
    dispatch({ type: types.SET_REPORT_SHARE_LINK, link });
  };
}

export function setPaperworksCountStatistics(result, chart) {
  return {
    type: types.SET_PAPERWORK_COUNT_STATISTICS,
    result,
    chart,
  };
}

export function setDocuments(apiDocs, storageDocs) {
  return async (dispatch) => {
    dispatch({ type: types.SET_DOCUMENTS, apiDocs, storageDocs });
  };
}

export function updatePaperworkMetadata(updatedPaperworkMetadata) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PAPERWORK_METADATA, updatedPaperworkMetadata });
  };
}

export function setCurrentRejection(rejection) {
  return async (dispatch) => {
    dispatch({ type: types.SET_CURRENT_REJECTION, rejection });
  };
}

export function setCurrentMendingRequest(mendingRequestReason, mendingRequestArea) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_MENDING_REQUEST,
      mendingRequestReason,
      mendingRequestArea,
    });
  };
}

export function setModifyMode() {
  return {
    type: types.SET_MODIFY_MODE,
  };
}

export function setPaperworkData(data) {
  return async (dispatch) => {
    dispatch({ type: types.SET_PAPERWORK_DATA, data });
  };
}
