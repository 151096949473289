import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkListByState } from "store/PaperWork/selectors";
import Item from "../Item";
import { ContainerWrap, Wrapper, PaginationWrap } from "./styled";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { getPaperworkProgress, getUserPaperworks } from "store/PaperWork/actions/requests";
import { IN_PROGRESS_KEY } from "../../functions";
import { FILTERS_HOME_DEFAULT } from "../../../Selector/components/Filters/constants";
import { filterByState } from "../../functions";
import { selectFilters } from "store/Filters/selectors";
import { getCustomFilters } from "store/Filters/functions";
import Pagination from "components/commons/Pagination";
import { setPaperworkPage } from "store/PaperWork/actions/actions";
import { selectPaperworkTotalCount, selectPaperworkPageSize, selectPaperworkActualPage } from "store/PaperWork/selectors";

const PaperworkInProgress = ({ search }) => {
  const dispatch = useDispatch();

  const IN_PROGRESS_STATES = [
    PAPERWORK_STATE.STARTED,
    PAPERWORK_STATE.PENDING,
    PAPERWORK_STATE.OBSERVED,
    PAPERWORK_STATE.APPROVE_REQUESTED,
    PAPERWORK_STATE.REJECT_REQUESTED,
    PAPERWORK_STATE.MENDING_REQUESTED,
  ];

  const paperworkList = useSelector((state) => selectPaperworkListByState(state, IN_PROGRESS_STATES));
  const filteredList = filterByState(paperworkList, search);

  const storeFilters = useSelector(selectFilters);

  const actualPage = useSelector(selectPaperworkActualPage);
  useEffect(() => {
    const customFilters = getCustomFilters(storeFilters, FILTERS_HOME_DEFAULT[IN_PROGRESS_KEY]);
    dispatch(getUserPaperworks(customFilters));
  }, [actualPage]);

  useEffect(() => {
    return () => dispatch(setPaperworkPage(0));
  }, []);

  const paperworkCount = useSelector(selectPaperworkTotalCount);
  const pageSize = useSelector(selectPaperworkPageSize);

  const Card = ({ item }) => {
    const isObserved = item.state === PAPERWORK_STATE.OBSERVED;
    const getDetail = (id) => dispatch(getPaperworkProgress(id));
    return (
      <Item
        state={item.state}
        title={item.paperworkProgressId}
        detail={item.paperworkName}
        onClick={isObserved ? () => getDetail(item.paperworkProgressId) : null}
      />
    );
  };

  const nextPage = () => {
    dispatch(setPaperworkPage(actualPage + 1));
  };

  const prevPage = () => {
    dispatch(setPaperworkPage(actualPage - 1));
  };

  return (
    <Wrapper>
      <ContainerWrap>
        {filteredList.map((item) => (
          <Card key={item.paperworkProgressId} item={item} />
        ))}
      </ContainerWrap>
      <PaginationWrap>
        <Pagination pageNumber={actualPage} limit={pageSize} total={paperworkCount} onPrev={prevPage} onNext={nextPage}/>
      </PaginationWrap>
    </Wrapper>
  );
};

export default PaperworkInProgress;
