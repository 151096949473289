/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Label } from "./styled";
import colors from "styles/colors";
import useResize from "hooks/useResize";
import CommonComboBox from "components/commons/ComboBox/CommonComboBox";

const Select = ({ profiles, error, label, errorMessage, onClick, onChange, val, disabled, placeholder, name }) => {
  const { screenWidth, screenHeight } = useResize();
  const height = screenWidth <= 905 ? "40px" : "48px";

  return (
    <Wrapper>
      <Label children={label} />
      <CommonComboBox
        name={name}
        options={profiles}
        errorMessage={errorMessage}
        error={error}
        height={height}
        onClick={(event) => onClick(event)}
        onChange={onChange}
        value={val}
        disabled={disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray50}`}
        borderRadius={"4px"}
        accessor={"name"}
      />
    </Wrapper>
  );
};

export default Select;
