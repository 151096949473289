import React, { useEffect } from "react";
import { Wrapper } from "./styled";
import Form from "./components/Form";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileDefaultRoute, selectTokenExpires, selectUser, selectUserId } from "store/Login/selectors";
import { BYPASS_LOGIN } from "components/pages/App/constants";
import { push } from "connected-react-router";
import { get } from "lodash";
import { internalSignOut } from "store/Login/actions/actions";

const ProfilePending = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const id = useSelector(selectUserId);
  const uid = get(user, "firebaseDataId", "");
  const profileId = get(user, "profileId", null);
  const internalUser = uid && profileId; //It is registered but has not yet been assigned a profile.
  const defaultRoute = useSelector(selectProfileDefaultRoute);
  const tokenExpires = useSelector(selectTokenExpires);
  const isTokenExpired = () => tokenExpires < Date.now() / 1000;

  const temporaryLogout = () => {
    setTimeout(() => {
      dispatch(internalSignOut());
    }, 5000);
  };

  useEffect(() => {
    if (id && (BYPASS_LOGIN || internalUser)) dispatch(push(defaultRoute));
    else if (!internalUser) temporaryLogout();
    else if (id && profileId && !isTokenExpired()) dispatch(push(defaultRoute));
  }, [user]);

  return (
    <Wrapper>
      <Form />
    </Wrapper>
  );
};

export default ProfilePending;
