import { PAPERWORK_STATE } from "store/PaperWork/constants";
export const DASHBOARD_FILTERS = [
  {
    title: "Detalles de trámite",
    sections: [
      {
        title: "Tipo",
        componentId: 1,
        columns: "1fr 1fr",
        seeMore: true,
        key: "paperworksTypes",
        elements: [
          { name: "Mejora de inmuebles", key: "E1" },
          { name: "Certificado catastral", key: "D1" },
          { name: "Revalidación de Certificado catastral", key: "D2" },
          { name: "Informe catastral", key: "D5" },
          // { name: "Valuación fiscal", Key: null },
          // { name: "Copia de cedula", Key: null },
          // { name: "Declaración de inmuebles", Key: null },
          // { name: "Formularios de Avalúo", Key: null },
          // { name: "Subsanamientos", Key: null },
          // { name: "Rutinas de validación", Key: null },
          // { name: "Presentación previa", Key: null },
          // { name: "Informe catastral", Key: null },
          // { name: "Calculo valuatorio", Key: null },
        ],
      },
      {
        title: "Fecha",
        componentId: 2,
        columns: "1fr 1fr",
        key: "date",
        elements: [
          { name: "Inicio", key: "dateFrom" },
          { name: "Fin", key: "dateTo" },
        ],
      },
      {
        title: "Estado",
        componentId: 1,
        columns: "1fr 1fr",
        seeMore: true,
        key: "paperworksStates",
        elements: [
          { name: "Aprobado", key: PAPERWORK_STATE.APPROVED },
          { name: "Rechazado", key: PAPERWORK_STATE.REJECTED },
          { name: "Observado", key: PAPERWORK_STATE.OBSERVED },
          { name: "Pendiente", key: PAPERWORK_STATE.PENDING },
          { name: "Validado", key: PAPERWORK_STATE.APPROVE_REQUESTED },
          { name: "Pendiente de rechazo", key: PAPERWORK_STATE.REJECT_REQUESTED },
          { name: "Pendiente de subsanar", key: PAPERWORK_STATE.MENDING_REQUESTED },
        ],
      },
      {
        title: "Número de trámite",
        componentId: 4,
        key: "paperworkProgressId",
        elements: [
          {
            placeholder: "Ingrese el número de trámite",
          },
        ],
      },
    ],
  },
  {
    title: "Datos del usuario",
    sections: [
      {
        title: "CUIL/CUIT",
        componentId: 4,
        key: "cuit",
        elements: [
          {
            placeholder: "Ingrese su número de CUIT/CUIL",
          },
        ],
      },
    ],
  },
  {
    title: "Información del inmueble",
    sections: [
      {
        title: "Departamento",
        componentId: 4,
        key: "department",
        length: 2,
        elements: [
          {
            placeholder: "Ingrese el número de departamento",
          },
        ],
      },
      {
        title: "Partida",
        componentId: 4,
        key: "departure",
        elements: [
          {
            placeholder: "Ingrese el número de Partida",
          },
        ],
      },
      {
        title: "Nomenclatura",
        componentId: 4,
        key: "nomenclature",
        elements: [
          {
            placeholder: "Ingrese la Nomenclatura",
          },
        ],
      },
    ],
  },
];

export const HOME_FILTERS = [
  {
    title: "Detalles de trámite",
    sections: [
      {
        title: "Tipo",
        componentId: 1,
        columns: "1fr 1fr",
        seeMore: true,
        key: "paperworksTypes",
        elements: [
          { name: "Mejora de inmuebles", key: "E1" },
          { name: "Certificado catastral", key: "D1" },
          { name: "Revalidación de Certificado catastral", key: "D2" },
          { name: "Informe catastral", key: "D5" },
          // { name: "Valuación fiscal", Key: null },
          // { name: "Copia de cedula", Key: null },
          // { name: "Declaración de inmuebles", Key: null },
          // { name: "Formularios de Avalúo", Key: null },
          // { name: "Subsanamientos", Key: null },
          // { name: "Rutinas de validación", Key: null },
          // { name: "Presentación previa", Key: null },
          // { name: "Informe catastral", Key: null },
          // { name: "Calculo valuatorio", Key: null },
        ],
      },
      {
        title: "Fecha",
        componentId: 2,
        columns: "1fr 1fr",
        key: "date",
        elements: [
          { name: "Inicio", key: "dateFrom" },
          { name: "Fin", key: "dateTo" },
        ],
      },
      {
        title: "Número de trámite",
        componentId: 4,
        key: "paperworkProgressId",
        elements: [
          {
            placeholder: "Ingrese el número de trámite",
            type: "integer",
          },
        ],
      },
    ],
  },
  {
    title: "Información del inmueble",
    sections: [
      {
        title: "Departamento",
        componentId: 4,
        key: "department",
        length: 2,
        elements: [
          {
            placeholder: "Ingrese el número de departamento",
          },
        ],
      },
      {
        title: "Partida",
        componentId: 4,
        key: "departure",
        elements: [
          {
            placeholder: "Ingrese el número de Partida",
          },
        ],
      },
      {
        title: "Nomenclatura",
        componentId: 4,
        key: "nomenclature",
        elements: [
          {
            placeholder: "Ingrese la Nomenclatura",
          },
        ],
      },
    ],
  },
];
