import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "components/commons/Modals/ConfirmModal";
import Image from "images/icons/blue/logout.svg";
import { sendPaperworkToReview } from "store/PaperWork/actions/requests";
import { selectPaperworkMetadata, selectPaperworkStructure } from "store/PaperWork/selectors";

const D1Confirm = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const { id: idPap } = useSelector(selectPaperworkStructure);
  const { id: idprog } = useSelector(selectPaperworkMetadata);
  const requestRevalidation = () => dispatch(sendPaperworkToReview(idPap, idprog));
  
  const title = "¿Estás seguro de solicitar la revalidación?";
  const description = "El trámite será recibido por un operario de catastro para realizar la revalidación";

  return <ConfirmModal action={requestRevalidation} title={title} description={description} open={open} toggle={toggle} icon={Image} />;
};

export default D1Confirm;
